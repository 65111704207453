import DataService from "./DataService";

const FormService = {}

FormService.get = function (data) {
    const formData = new FormData();

    for (const key in data) {
        if (!data[key]) {
            continue;
        }

        if (data[key]?.hasOwnProperty("fileList")) {

            data[key]?.fileList?.map((file) => {
                formData.append(key, file.originFileObj);
            })

            continue;
        }

        formData.append(key, DataService.encrypt(data[key]));
    }

    return formData
}


export default FormService;