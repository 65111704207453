import {env} from "../configs/EnvironmentConfig";
import CryptoJS from 'crypto-js';

const secretKey = env.SECRET_KEY
const ivKey = env.IV_KEY
const DataService = {}

function isNumber(str) {
    return !isNaN(str) && str.trim() !== '';
}

DataService.decryptObject = function (data) {
    let obj = Array.isArray(data) ? [] : {};
    if (typeof obj === 'object') {
        for (const key in data) {
            let depKey = isNumber(key) ? null : DataService.decrypt(key)

            const value = data[key];
            if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                const DepValue = DataService.decryptObject(value);
                if (depKey) {
                    obj[depKey] = DepValue
                } else {
                    try {
                        obj.push(DepValue)
                    } catch (e) {
                    }
                }
            } else if(Array.isArray(value) && value !== null) {
                obj[depKey] = value.map((item) => {
                    return typeof item === 'object' ? DataService.decryptObject(item) : DataService.decrypt(item);
                });
            } else {
                obj[depKey] = DataService.decrypt(value);
            }
        }
    }
    return obj;
}

DataService.encrypt = function (data) {
    if (!secretKey || !ivKey) {
        return data;
    }

    const key = CryptoJS.enc.Base64.parse(secretKey);
    const iv = CryptoJS.enc.Base64.parse(ivKey);

    const encryptedData = CryptoJS.AES.encrypt(data, key, {
        iv: iv,
    });

    return encryptedData.toString()
}

DataService.decrypt = function (data) {
    if (!secretKey || !ivKey) {
        return data;
    }

    const key = CryptoJS.enc.Base64.parse(secretKey);
    const iv = CryptoJS.enc.Base64.parse(ivKey);

    const decryptedData = CryptoJS.AES.decrypt(data, key, {
        iv: iv
    });

    try {
        return decryptedData.toString(CryptoJS.enc.Utf8);
    } catch (error) {
        console.log( data);
        return data
    }
}


export default DataService;