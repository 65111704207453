import { NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'
import {routes} from "./routes";
import {LANG, SIDENAV_COL, THEME} from "../constants/AppConstant";

export const APP_NAME = 'Adonis Travel';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const REDIRECT_URL_KEY = 'redirect'
export const AUTHENTICATED_ENTRY = routes.dashboard;

export const THEME_CONFIG = {
	navCollapsed: JSON.parse(localStorage.getItem(SIDENAV_COL)) ||  false,
	locale: localStorage.getItem(LANG) || 'en',
	navType: NAV_TYPE_SIDE,
	headerNavColor: '',
	mobileNav: false,
	currentTheme: localStorage.getItem(THEME) || 'light',
	direction: DIR_LTR,
	blankLayout: false
};
