import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {AUTH_TOKEN, AUTH_USER} from 'constants/AuthConstant';
import AuthService from 'services/AuthService';
import DataService from "../../services/DataService";
import {routes} from "../../configs/routes";

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null
}

export const signIn = createAsyncThunk('auth/login',async (data, { rejectWithValue }) => {
	const { email_username, password } = data
	try {
		let response = await AuthService.login({email_username, password})
		response = DataService.decryptObject(response);
		localStorage.setItem(AUTH_TOKEN, response.token);
		localStorage.setItem(AUTH_USER, JSON.stringify(response.user));
		return 'done'
	} catch (err) {
		const error = DataService.decryptObject(err.response?.data);
		return rejectWithValue(error?.errors || 'Error')
	}
})

export const signOut = createAsyncThunk('auth/logout',async () => {
    // const response = await AuthService.logout()
	localStorage.removeItem(AUTH_TOKEN);
    return 'done'
})

export const forgetPassword = createAsyncThunk('auth/forget',async (data, { rejectWithValue }) => {
	const { email } = data
	try {
		await AuthService.forget({email})
		return 'done'
	} catch (err) {
		const error = DataService.decryptObject(err.response?.data);
		return rejectWithValue(error?.errors || 'Error')
	}
})

export const resetPassword = createAsyncThunk('auth/reset',async (data, { rejectWithValue }) => {
	const { token, new_password, verify_password } = data
	try {
		await AuthService.reset({token, new_password, verify_password})
		return 'done'
	} catch (err) {
		const error = DataService.decryptObject(err.response?.data);
		return rejectWithValue(error?.errors || 'Error')
	}
})

export const assignPassword = createAsyncThunk('auth/assign-password',async (data, { rejectWithValue }) => {
	const { token, new_password, verify_password } = data
	try {
		await AuthService.assign({token, new_password, verify_password})
		return 'done'
	} catch (err) {
		const error = DataService.decryptObject(err.response?.data);
		return rejectWithValue(error?.errors || 'Error')
	}
})



export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false
			state.redirect = '/'
			state.token = action.payload
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideAuthMessage: (state) => {
			state.message = ''
			state.showMessage = false
		},
		signOutSuccess: (state) => {
			state.loading = false
			state.token = null
			state.redirect = '/'
		},
		showLoading: (state) => {
			state.loading = true
		},
		signInSuccess: (state, action) => {
			state.loading = false
			state.token = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state) => {
				state.loading = true
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signIn.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signOut.fulfilled, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			.addCase(signOut.rejected, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			.addCase(forgetPassword.pending, (state) => {
				state.loading = true
			})
			.addCase(forgetPassword.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = routes.reset
			})
			.addCase(forgetPassword.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(resetPassword.pending, (state) => {
				state.loading = true
			})
			.addCase(resetPassword.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = routes.login
			})
			.addCase(resetPassword.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(assignPassword.pending, (state) => {
				state.loading = true
			})
			.addCase(assignPassword.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = routes.login
			})
			.addCase(assignPassword.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
	},
})

export const { 
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess
} = authSlice.actions

export default authSlice.reducer