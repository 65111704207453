export const routes = {
    login: '/login',
    forget: '/forgot',
    reset: '/reset',
    assign: '/assign-password',
    dashboard: '/',
    users: '/users',
    monitor: '/monitor/error/*',
    monitorEntity: '/monitor/entity/*',
    settings: '/settings/*',
    support: '/support/*',
    supportNav: '/support/',
    cpanelUsers: '/cpanel/users',
    cpanelForwards: '/cpanel/forwards',
    CpanelAutoresponders: '/cpanel/autoresponders/*',
    newsletterUsers: '/newsletter/users/*',
    newsletterGroups: '/newsletter/groups/*',
    newsletter: '/newsletter/campaigns/*',
    newsletterTemplates: '/notifier/templates/*',
    newsletterCompany: '/notifier/company',
    notifierTemplateSetting: '/notifier/setting',
}